/** @format */

const bilastikItem = [
  {
    id: 64,
    cardImage: "/images/home5.jpg",
    cardTitle:
      "سواتر اخشاب بلاستيك الرياض افضل شرائح بلاستيك للجدران بالرياض شركة سواتر",
  },
  {
    id: 65,
    cardImage: "/images/alriyad10.jpg",
    cardTitle: "سواتر خشب بلاستيك|أفضل سعر لمتر الخشب البلاستيكي",
  },
];
export default bilastikItem;
