/** @format */

const shinkuItem = [
  {
    id: 130,
    cardImage: "/images/home1.jpg",

    cardTitle:
      "مدينة الخيام المظلات - تفصيل خيام ملكي ومظلات وسواتر وموقف سيارات ومظلات مدارس القصيم بريده عنيزه 0500173090",
  },
  {
    id: 131,
    cardImage: "/images/qumash2.jpg",

    cardTitle:
      "مدينة الخيام المظلات - تفصيل خيام ملكي ومظلات وسواتر وموقف سيارات ومظلات مدارس القصيم بريده عنيزه 0500173090",
  },
];
export default shinkuItem;
