/** @format */

const shubukItem = [
  {
    id: 134,
    cardImage: "/images/biutshaer1.jpeg",
    cardTitle:
      "تركيب شبوك القصيم بريده عنيزه | أفضل اسعار و أنواع الشبوك 0500173090",
  },
];
export default shubukItem;
