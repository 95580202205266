/** @format */

const biutShaerItem = [
  {
    id: 66,
    cardImage: "/images/biutshaer1.jpeg",
    cardTitle: "بيوت شعر وخيام تفصيل وتركيب شركة اختيار الرياض",
  },
];
export default biutShaerItem;
