/** @format */

const masajidItem = [
  {
    id: 100,
    cardImage: "/images/masabih1.jpg",
    cardTitle:
      "مظلات مسابح - تغطية المسابح بالقماش و بالزجاج ومادة اللكسان البلاستيك",
  },
  {
    id: 101,
    cardImage: "/images/masabih2.jpg",
    cardTitle: "تركيب افضل مظلات مسابح - تغطية بالكسان الشفاف والقماش ",
  },
];
export default masajidItem;
