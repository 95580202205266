/** @format */

const qumash1Item = [
  {
    id: 104,
    cardImage: "/images/home1.jpg",

    cardTitle:
      "سواتر قماش القصيم بريده عنيزه اشكال سواتر قماش بولي ايثيلين للمنازل في القصيم بريده عنيزه",
  },
  {
    id: 105,
    cardImage: "/images/qumash2.jpg",

    cardTitle: "سواتر قماش - تركيب شركة اختيار - 0500173090",
  },
];
export default qumash1Item;
