/** @format */

const madakhilItem = [
  {
    id: 83,
    cardImage: "/images/mudakhil1.jpg",
    cardTitle:
      " تركيب مظلات فوق الابواب للفلل والبيوت القصيم بريده عنيزه 0500173090",
  },
  {
    id: 84,
    cardImage: "/images/mudakhil2.jpg",
    cardTitle:
      "مظلات مداخل القصيم بريده عنيزه | أفضل تصاميم مظلات فوق الابواب عصرية بأرخص الأسعار",
  },
];
export default madakhilItem;
