/** @format */

const madarisItem = [
  {
    cardImage: "/images/slider1.jpg",
    cardTitle:
      "مظلات مسابح - تغطية المسابح بالقماش و بالزجاج ومادة اللكسان البلاستيك",
  },
  {
    cardImage: "/images/slider2.jpg",
    cardTitle: "تركيب افضل مظلات مسابح - تغطية بالكسان الشفاف والقماش ",
  },
];

export default madarisItem;
