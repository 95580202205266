/** @format */

const wasawatirItem = [
  {
    id: 135,
    cardImage: "/images/home3.jpg",
    cardSpan: "سواتر قماش",
    cardTitle:
      "مظلات وسواتر في القصيم بريده عنيزه باقل الاسعار عروض وتخفيضات من مظلات وسواتر في القصيم بريده عنيزه",
  },
];
export default wasawatirItem;
