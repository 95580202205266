/** @format */

const aswaqItem = [
  {
    id: 62,
    cardImage: "/images/slider3.jpg",
    cardTitle:
      "سواتر اخشاب بلاستيك الرياض افضل شرائح بلاستيك للجدران بالرياض شركة سواتر",
  },
  {
    id: 63,
    cardImage: "/images/slider4.jpg",
    cardTitle: "سواتر خشب بلاستيك|أفضل سعر لمتر الخشب البلاستيكي",
  },
];
export default aswaqItem;
