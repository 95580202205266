/** @format */

const likasan1Item = [
  {
    id: 82,
    cardImage: "/images/liksan1.jpg",
    cardTitle:
      "تركيب سواتر لكسان زجاج القصيم بريده عنيزه تصاميم جديدة  0500173090",
  },
];
export default likasan1Item;
