/** @format */

const likasanItem = [
  {
    id: 81,
    cardImage: "/images/liksan2.jpg",
    cardTitle: "مظلات شفافة زجاجية | أفضل شركة تركيب الكسان السعودية",
  },
];
export default likasanItem;
