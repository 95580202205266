/** @format */

const masabih1Item = [
  {
    id: 99,
    cardImage: "/images/slider1.jpg",
    cardTitle: "سواتر مسابح | سواتر لكسان للمسابح بأقل التكاليف",
  },
];
export default masabih1Item;
