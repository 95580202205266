/** @format */

const qumashItem = [
  {
    id: 102,
    cardImage: "/images/home1.jpg",

    cardTitle:
      "سواتر قماش القصيم بريده عنيزه اشكال سواتر قماش بولي ايثيلين للمنازل في القصيم بريده عنيزه",
  },
  {
    id: 103,
    cardImage: "/images/qumash2.jpg",

    cardTitle: "سواتر قماش - تركيب شركة اختيار - 0500173090",
  },
];
export default qumashItem;
